import { defineComponent } from 'vue';
import { deleteQuarterlyProduction, getQuarterlyProduction } from '@/api/dataentry-api-generated';
import QuartlyProductionDialog from '@/components/QuartlyProduction/QuartlyProductionDialog.vue';
import QuartlyProductionDuplicationDialog from '@/components/QuartlyProduction/QuartlyProductionDuplicationDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'QuartileProductions',
    components: { QuartlyProductionDialog, DeleteDialog, QuartlyProductionDuplicationDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'qrtProductionProcessorLabel',
                    name: 'processor',
                    label: 'Processor',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'yearEntry',
                    name: 'yearEntry',
                    label: 'Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'quarterEntry',
                    name: 'quarterEntry',
                    label: 'Quarter',
                    sortable: true,
                    align: 'center',
                    format: (val) => val || '-'
                },
                {
                    field: 'processedSpecies',
                    name: 'processedSpecies',
                    label: 'Species Processed',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'tallowTonnes',
                    name: 'tallowTonnes',
                    label: 'Tallow Tonnes',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'mealMealTonnes',
                    name: 'mealMealTonnes',
                    label: 'Meal Meal Tonnes',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'bloodMealTonnes',
                    name: 'bloodMealTonnes',
                    label: 'Blood Meal Tonnes',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalHeadCapacityPerYear',
                    name: 'totalHeadCapacityPerYear',
                    label: 'Total Head Capacity Per Year',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'offalEdibleCoProductsSold',
                    name: 'offalEdibleCoProductsSold',
                    label: 'Offal - Edible / Co-Products Sold',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'skinsHidesSold',
                    name: 'skinsHidesSold',
                    label: 'Skins / Hides Sold',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalHscwProcessedForQuarter',
                    name: 'totalHscwProcessedForQuarter',
                    label: 'Total HsCW Processed for Quarter',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalHeadProcessedForQuarter',
                    name: 'totalHeadProcessedForQuarter',
                    label: 'Total Head Processed for Quarter',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalHscwGrainFedCattle',
                    name: 'totalHscwGrainFedCattle',
                    label: 'Total HsCW Grain Fed Cattle',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalHscwGrassFedCattle',
                    name: 'totalHscwGrassFedCattle',
                    label: 'Total HsCW Grass Fed Cattle',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'totalLiveWeightCattle',
                    name: 'totalLiveWeightCattle',
                    label: 'Total Live Weight (Cattle)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'mondayMorningShiftForHarvestSlaughter',
                    name: 'mondayMorningShiftForHarvestSlaughter',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayMorningShiftForHarvestSlaughter',
                    name: 'tuesdayMorningShiftForHarvestSlaughter',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayMorningShiftForHarvestSlaughter',
                    name: 'wednesdayMorningShiftForHarvestSlaughter',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayMorningShiftForHarvestSlaughter',
                    name: 'thursdayMorningShiftForHarvestSlaughter',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayMorningShiftForHarvestSlaughter',
                    name: 'fridayMorningShiftForHarvestSlaughter',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayMorningShiftForHarvestSlaughter',
                    name: 'saturdayMorningShiftForHarvestSlaughter',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayMorningShiftForHarvestSlaughter',
                    name: 'sundayMorningShiftForHarvestSlaughter',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'mondayAfternoonShiftForHarvestSlaughter',
                    name: 'mondayAfternoonShiftForHarvestSlaughter',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayAfternoonShiftForHarvestSlaughter',
                    name: 'tuesdayAfternoonShiftForHarvestSlaughter',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayAfternoonShiftForHarvestSlaughter',
                    name: 'wednesdayAfternoonShiftForHarvestSlaughter',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayAfternoonShiftForHarvestSlaughter',
                    name: 'thursdayAfternoonShiftForHarvestSlaughter',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayAfternoonShiftForHarvestSlaughter',
                    name: 'fridayAfternoonShiftForHarvestSlaughter',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayAfternoonShiftForHarvestSlaughter',
                    name: 'saturdayAfternoonShiftForHarvestSlaughter',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayAfternoonShiftForHarvestSlaughter',
                    name: 'sundayAfternoonShiftForHarvestSlaughter',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'mondayEveningShiftForHarvestSlaughter',
                    name: 'mondayEveningShiftForHarvestSlaughter',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayEveningShiftForHarvestSlaughter',
                    name: 'tuesdayEveningShiftForHarvestSlaughter',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayEveningShiftForHarvestSlaughter',
                    name: 'wednesdayEveningShiftForHarvestSlaughter',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayEveningShiftForHarvestSlaughter',
                    name: 'thursdayEveningShiftForHarvestSlaughter',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayEveningShiftForHarvestSlaughter',
                    name: 'fridayEveningShiftForHarvestSlaughter',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayEveningShiftForHarvestSlaughter',
                    name: 'saturdayEveningShiftForHarvestSlaughter',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayEveningShiftForHarvestSlaughter',
                    name: 'sundayEveningShiftForHarvestSlaughter',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'noOfLinesPerRoomForHarvestSlaughter',
                    name: 'noOfLinesPerRoomForHarvestSlaughter',
                    label: 'Number of Lines Per Room',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'avgLineSpeedForHarvestSlaughter',
                    name: 'avgLineSpeedForHarvestSlaughter',
                    label: 'Average Line Speed (Hd/hr)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'mondayMorningShiftForFabricationBoningRoom',
                    name: 'mondayMorningShiftForFabricationBoningRoom',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayMorningShiftForFabricationBoningRoom',
                    name: 'tuesdayMorningShiftForFabricationBoningRoom',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayMorningShiftForFabricationBoningRoom',
                    name: 'wednesdayMorningShiftForFabricationBoningRoom',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayMorningShiftForFabricationBoningRoom',
                    name: 'thursdayMorningShiftForFabricationBoningRoom',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayMorningShiftForFabricationBoningRoom',
                    name: 'fridayMorningShiftForFabricationBoningRoom',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayMorningShiftForFabricationBoningRoom',
                    name: 'saturdayMorningShiftForFabricationBoningRoom',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayMorningShiftForFabricationBoningRoom',
                    name: 'sundayMorningShiftForFabricationBoningRoom',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'mondayAfternoonShiftForFabricationBoningRoom',
                    name: 'mondayAfternoonShiftForFabricationBoningRoom',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayAfternoonShiftForFabricationBoningRoom',
                    name: 'tuesdayAfternoonShiftForFabricationBoningRoom',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayAfternoonShiftForFabricationBoningRoom',
                    name: 'wednesdayAfternoonShiftForFabricationBoningRoom',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayAfternoonShiftForFabricationBoningRoom',
                    name: 'thursdayAfternoonShiftForFabricationBoningRoom',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayAfternoonShiftForFabricationBoningRoom',
                    name: 'fridayAfternoonShiftForFabricationBoningRoom',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayAfternoonShiftForFabricationBoningRoom',
                    name: 'saturdayAfternoonShiftForFabricationBoningRoom',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayAfternoonShiftForFabricationBoningRoom',
                    name: 'sundayAfternoonShiftForFabricationBoningRoom',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'mondayEveningShiftForFabricationBoningRoom',
                    name: 'mondayEveningShiftForFabricationBoningRoom',
                    label: 'Monday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'tuesdayEveningShiftForFabricationBoningRoom',
                    name: 'tuesdayEveningShiftForFabricationBoningRoom',
                    label: 'Tuesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'wednesdayEveningShiftForFabricationBoningRoom',
                    name: 'wednesdayEveningShiftForFabricationBoningRoom',
                    label: 'Wednesday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'thursdayEveningShiftForFabricationBoningRoom',
                    name: 'thursdayEveningShiftForFabricationBoningRoom',
                    label: 'Thursday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'fridayEveningShiftForFabricationBoningRoom',
                    name: 'fridayEveningShiftForFabricationBoningRoom',
                    label: 'Friday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'saturdayEveningShiftForFabricationBoningRoom',
                    name: 'saturdayEveningShiftForFabricationBoningRoom',
                    label: 'Saturday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'sundayEveningShiftForFabricationBoningRoom',
                    name: 'sundayEveningShiftForFabricationBoningRoom',
                    label: 'Sunday',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === true ? 'Yes' : 'No' || '-'
                },
                {
                    field: 'noOfLinesPerRoomForFabricationBoningRoom',
                    name: 'noOfLinesPerRoomForFabricationBoningRoom',
                    label: 'Number of Lines Per Room',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
                {
                    field: 'avgLineSpeedForFabricationBoningRoom',
                    name: 'avgLineSpeedForFabricationBoningRoom',
                    label: 'Average Line Speed (Hd/hr)',
                    sortable: true,
                    align: 'center',
                    format: (val) => val === null ? '-' : val
                },
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'processor',
                descending: false,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Quarterly Productions' : 'Quarterly Production';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getQuarterlyProduction({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Quartile Production', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteQuarterlyProduction(i.uuid);
                    this.$log.addMessage('Successfully deleted Quarterly Production');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
